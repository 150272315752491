<template>
  <div class="nav-box">
    <div class="nav-box-wapper">
        <LeftSlogan @onClickHome="onClickTab"></LeftSlogan>
        <div class="nav-box-right">
          <div class="nav-box-right-nav">
            <ul ref="navBoxRightNavUl">
              <router-link to="/home/data"><li @click="onClickTab(0)" :class="[showTab === 0 ? 'ul-li':'']">New</li></router-link>
              <router-link to="/home/history"><li @click="onClickTab(1)" :class="[showTab === 1 ? 'ul-li':'']">History</li></router-link>
              <router-link to="/home/rank"><li @click="onClickTab(2)" :class="[showTab === 2 ? 'ul-li':'']">Ranking</li></router-link>
            </ul>
          </div>
          <RightUser></RightUser>
        </div>
    </div>
    <NotClosedTask :isShow="showNotClosedTask"></NotClosedTask>
  </div>
</template>

<script>
import LeftSlogan from '@/components/Nav/LeftSlogan.vue'
import RightUser from '@/components/Nav/RightUser.vue'
import NotClosedTask from '@/components/NotClosedTask/NotClosedTask.vue'
export default {
  name: 'NavName',
  data () {
    return {
      showTab: -1,
      showNotClosedTask: false
    }
  },
  components: {
    LeftSlogan,
    RightUser,
    NotClosedTask
  },
  methods: {
    onClickTab (value) {
      if (value === 0 && this.$bus.hasNotClosedTask) {
        value = -1
      }
      this.showTab = value
    },
    initNotClosedTask () {
      this.$api.task_info().then(response => {
        const data = response.data.history
        this.$bus.userName = response.data.name
        if (data.length > 0) {
          this.showNotClosedTask = true
          this.$bus.hasNotClosedTask = true
          this.$bus.locustName = data[0].name
          this.$bus.nodeNum = data[0].node_num
          this.$bus.workerNum = data[0].worker_num
          this.$bus.rate = data[0].rate
          this.$bus.host = data[0].host
          this.$bus.projectName = data[0].project_name
          this.$bus.createTime = data[0].created_at
          this.$bus.costTime = data[0].current_time - data[0].created_at
          this.$bus.status = data[0].status === 0 ? 'Stopped' : (data[0].status === 1 ? 'Running' : 'Completed')
        }
      })
    }
  },
  mounted () {
    this.initNotClosedTask()
  }
}
</script>

<style lang="less" scoped>
.nav-box {
  height: 70px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #eee;
  position: fixed;
  top:0;
  z-index:10;

  .nav-box-wapper{
    height: 100%;
    width: 95%;
    margin: 0 auto;
  }
  .nav-box-right {
    height: 70px;
    width: 450px;
    float: right;

    .nav-box-right-nav {
      float: left;
    }

    ul li {
      display: inline-block;
      width: 90px;
      height: 68px;
      line-height: 68px;
      text-align: center;
      font-size: 16px;
      color: #333;
      cursor: pointer;
      transition-property: color;
      transition-duration: 0.5s;
    }

    ul li:hover {
      color: #1890ff;
      border-bottom: 2px solid #1890ff;
    }
    .ul-li {
      color: #1890ff;
      border-bottom: 2px solid #1890ff;
    }
  }
}

</style>
