<template>
  <div class="nav-box-right-user">
    <i class="nav-box-right-user-logo"></i>
    <span class="nav-box-right-user-span">{{ this.$bus.userName }}</span>
    <div @click="onLogout" class="nav-box-right-user-logout">
      <img src="../../../static/icon/logout.png" class="nav-box-right-user-logout-img">
      logout
    </div>
  </div>
</template>

<script>
// import { userIcon, logoutIcon } from '@/core/icons.js'
// import storage from 'store'

export default {
  props: {
    nickName: {
      type: String,
      default: 'test.user'
    }
  },
  methods: {
    onLogout () {
      this.$api.logout().then(response => {
        if (response.status === 'success') {
          const background = document.getElementById('watermark')
          background.style.backgroundImage = null
          this.$cookies.remove('is_login')
          this.$cookies.remove('code')
          this.$router.push('/')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.nav-box-right-user {
  height: 70px;
  width: 175px;
  float: right;
  color: #333;
  text-align: center;
  line-height: 70px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .nav-box-right-user-logo {
    display: inline-block;
    height: 25px;
    width: 25px;
    // background-color: red;
    vertical-align: middle;
    background-image: url("../../../static/icon/user.png");
    background-size: 25px 25px;
  }

  span {
    margin-left: 6px;
  }

  .nav-box-right-user-logout {
    height: 70px;
    width: 175px;
    background-color: #fcfcfc;
    line-height: 70px;
    position: absolute;
    bottom: -70px;
    transition:all 1s;

    .nav-box-right-user-logout-img {
      height:23px;
      vertical-align: middle;
    }
  }
}

// .nav-box-right-user:hover .nav-box-right-user-span{
//   color: #1890ff;
// }

// .nav-box-right-user:hover .nav-box-right-user-logo {
//   background-image: url("../../../static/icon/user_hover.png");
// }

.nav-box-right-user:hover .nav-box-right-user-logout {
  transform: translateY(-100%);
}

.nav-box-right-user-logout:hover {
  color: #333;
}

</style>
