/* eslint-disable camelcase */
import service from '@/utils/request'

// 初始化 获取信息
const login = body => {
  return service.post('/api/login', body)
}

const logout = params => {
  return service.get('/api/logout', { params: params })
}

const ranking = params => {
  return service.get('/api/ranking', { params: params })
}

const create = body => {
  return service.post('/api/create', body)
}

const check_status = body => {
  return service.post('/api/check_status', body)
}

const swarm = body => {
  return service.post('/api/swarm', body)
}

const request_stats = params => {
  return service.get('/api/request_stats', { params: params })
}

const stop = body => {
  return service.post('/api/stop', body)
}

const record_and_end = body => {
  return service.post('/api/record_and_end', body)
}

const detail = params => {
  return service.get('/api/detail', { params: params })
}

const task_info = params => {
  return service.get('/api/task_info', { params: params })
}

const reset = body => {
  return service.post('/api/reset', body)
}

const setting = params => {
  return service.get('/api/setting', { params: params })
}

export default {
  login,
  ranking,
  create,
  check_status,
  swarm,
  request_stats,
  stop,
  record_and_end,
  detail,
  task_info,
  logout,
  reset,
  setting
}
