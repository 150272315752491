import Vue from 'vue'

export default new Vue({
  data () {
    return {
      userName: '',
      locustName: '',
      nodeNum: 1,
      workerNum: 1,
      rate: 1,
      resCode: '',
      host: 'testtesttesttest.ff.garena.all',
      projectName: 'test.ff.garena.all',
      createTime: 0,
      costTime: 0,
      hasNotClosedTask: false,
      status: 'ready',
      openResultWithBtn: false,
      // 控制 用户未刷新 & 资源未释放
      currentNotClosedTask: false,
      callbackUrl: ''
    }
  }
})
