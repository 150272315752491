<template>
  <div class="footer">
    <div class="footer-box">
      <p>Copyright © 2022 Made By Garena SH Web Team</p>
      <p>Version: 1.0</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterName'
}
</script>

<style lang="less" scoped>
.footer {
  height:140px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #727272;
  .footer-box {
    padding-top: 60px;
    height: 60px;
    width: 400px;
    margin: 0 auto;
  }
}
</style>
