<template>
  <div class="result-wapper-box">
    <div class="result">
      <div class="result-tab">
        <div class="result-tab-top">
          <div class="result-tab-top-shadow">
            <ul>
              <li>
                <span>Create Time</span>
                <p>{{ formateTime(createTime) }}</p>
              </li>
              <li>
                <span>Cost Time</span>
                <p>{{ comCostTime }}</p>
              </li>
              <li>
                <span>Host</span>
                <p>{{ host }}</p>
              </li>
              <li>
                <span>Status</span>
                <p>{{ status }}</p>
              </li>
              <li>
                <span>Nodes</span>
                <p>{{ nodes }}</p>
              </li>
              <li>
                <span>RPS</span>
                <p>{{ rps }}</p>
              </li>
              <li>
                <span>failures</span>
                <p>{{ failures }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="result-tab-bottom">
          <button @click="onRestart" class="button-first-child">Restart</button>
          <button @click="onStop" class="button-second-child">Stop</button>
          <div class="result-tab-bottom-popconfirm">
            <button @click="doublePopConfirm" :disabled="isshowPopConfirm" class="button-third-child">Record&End</button>
            <PopConfirm @popConfirmChange="popConfirmChangeMethod" :isShow="showPopConfirm"></PopConfirm>
          </div>
        </div>
      </div>
      <div class="result-content">
        <div class="result-content-info">
          <div class="result-content-info-box">
            <table border="1" cellspacing="0">
              <thead>
                <tr>
                  <th width='10%'>Type</th>
                  <th width='10%'>Path</th>
                  <th width='12%'>#Request</th>
                  <th width='12%'>#Fail</th>
                  <th width='10%'>Median (ms)</th>
                  <th width='10%'>Average (ms)</th>
                  <th width='8%'>Min</th>
                  <th width='8%'>Max</th>
                  <th width='10%'>Average size(bytes)</th>
                  <th width='10%'>Current RPS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in contentTable" :key="index">
                  <td>{{ item.method }}</td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.num_requests }}</td>
                  <td>{{ item.num_failures }}</td>
                  <td>{{ item.median_response_time }}</td>
                  <td>{{ item.avg_response_time }}</td>
                  <td>{{ item.min_response_time }}</td>
                  <td>{{ item.max_response_time }}</td>
                  <td>{{ item.avg_content_length }}</td>
                  <td>{{ item.current_rps }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="result-content-draw">

        </div>
      </div>
      <Completed :isShow="showCompleted"></Completed>
  </div>
  </div>

</template>

<script>
import Completed from '@/components/Completed/Completed.vue'
import PopConfirm from '@/components/PopConfirm/PopConfirm.vue'
import { getTime } from '@/utils/assist'
export default {
  data () {
    return {
      showCompleted: false,
      showPopConfirm: 1,
      isshowPopConfirm: false,
      // header
      createTime: this.$bus.createTime,
      currentTime: 0,
      costTime: '2022:04:23 21:48:00',
      host: this.$bus.host,
      status: this.$bus.status,
      nodes: this.$bus.nodeNum,
      rps: 0,
      failures: 0,
      // content
      contentTable: [],
      initTimer: null
    }
  },
  name: 'ResultName',
  methods: {
    formateTime (time) {
      return getTime(time)
    },
    doublePopConfirm () {
      this.showPopConfirm++
    },
    loadContentTable (responseData) {
      this.status = responseData.data.state
      this.currentTime = responseData.data.current_time
      this.contentTable = responseData.data.stats.filter(item => item.safe_name !== 'Aggregated')
      const currentItem = responseData.data.stats.filter(item => item.safe_name === 'Aggregated')
      this.failures = Math.floor((currentItem[0].num_failures / currentItem[0].num_requests) * 100) + '%'
      this.rps = responseData.data.total_rps
    },
    loadRequestStats () {
      this.$api.request_stats({
        name: this.$bus.locustName
      }).then(response => {
        this.loadContentTable(response)
      })
    },
    initResult () {
      // 第一次进来/再次开始
      this.loadRequestStats()
      this.initTimer = setInterval(() => {
        this.loadRequestStats()
      }, 10000)
    },
    onStop () {
      this.$api.stop({
        name: this.$bus.locustName,
        project_name: this.$bus.projectName
      }).then(response => {
        if (response.status === 'success') {
          clearInterval(this.initTimer)
          this.initTimer = null
          this.loadContentTable(response)
          this.$message({
            message: 'stop success!',
            type: 'success',
            duration: '3000'
          })
        } else {
          this.$message({
            message: 'stop error, please try again!',
            type: 'error',
            duration: '3000'
          })
        }
      })
    },
    onRestart () {
      // request stop
      this.$api.reset({
        name: this.$bus.locustName
      }).then(response => {
        if (response.status === 'success') {
          // request swarm
          this.$api.swarm({
            name: this.$bus.locustName,
            host: this.$bus.host,
            spawn_rate: this.$bus.rate,
            user_count: this.$bus.workerNum
          }).then(response => {
            if (response.status === 'success') {
              this.initResult()
              this.$message({
                message: 'restart success!',
                type: 'success',
                duration: '3000'
              })
            } else {
              this.$message({
                message: 'restart error, please try again!',
                type: 'error',
                duration: '3000'
              })
            }
          })
        } else {
          this.$message({
            message: 'restart error, please try again!',
            type: 'error',
            duration: '3000'
          })
        }
      })
    },
    popConfirmChangeMethod (value) {
      this.doublePopConfirm()
      if (value) {
        this.$api.record_and_end({
          name: this.$bus.locustName,
          project_name: this.$bus.projectName
        }).then(response => {
          if (response.status === 'success') {
            clearInterval(this.initTimer)
            this.initTimer = null
            this.loadContentTable(response)
            this.isshowPopConfirm = true
            this.showCompleted = true
            this.$bus.currentNotClosedTask = false
            this.$message({
              message: 'record and end success!',
              type: 'success',
              duration: '3000'
            })
          } else {
            this.$message({
              message: 'record and end error, please try again!',
              type: 'error',
              duration: '3000'
            })
          }
        })
      }
    }
  },
  components: {
    Completed,
    PopConfirm
  },
  computed: {
    comCostTime () {
      const resTime = this.currentTime - this.createTime
      let resHour = 0
      let resMin = 0
      if (resTime < 60) {
        return '00:00:' + `${resTime}`
      }
      if (resTime > 3600) {
        resHour = Math.floor(resTime / 3600)
        resMin = Math.floor((resTime - (resHour * 3600)) / 60)
      } else {
        resMin = Math.floor((resTime - (resHour * 3600)) / 60)
      }
      resHour = resHour.toString().length === 2 ? resHour.toString() : '0' + resHour.toString()
      resMin = resMin.toString().length === 2 ? resMin.toString() : '0' + resMin.toString()
      return `${resHour}:${resMin}:00`
    }
  },
  mounted () {
    if (!this.$bus.openResultWithBtn) {
      // this.$message({
      //   message: 'access not allowed!',
      //   type: 'error',
      //   duration: '3000'
      // })
      this.$router.go(-1)
    }
    // this.$bus.openResultWithBtn = false
    this.initResult()
    document.querySelector('.result-tab-top-shadow').style.width = (this.host.length * 8) + 800 + 'px'
    let maxlength = 0
    this.contentTable.forEach(item => {
      if (item.path.length > maxlength) {
        maxlength = item.path.length
      }
    })
    this.$bus.currentNotClosedTask = true
  },
  beforeDestory () {
    clearInterval(this.initTimer)
    this.initTimer = null
  }
}
</script>

<style lang="less" scoped>
.result-wapper-box {
  margin-top: 100px;
  height: 100%;
}
.result {
  width:90%;
  margin: 0 auto;

  .result-tab {
    height: 100px;
    width: 95%;
    border: 1px solid #dedede;
    margin: 0 auto;
    color: #333;
    font-size: 15px;
    background-color: #fff;
    padding-top: 40px;

    .result-tab-top {
      height:50px;
      width: 70%;
      overflow: hidden;
      overflow-x: scroll;
      white-space: nowrap;
      float: left;
      .result-tab-top-shadow {
        height: 40px;
        ul {
          li:hover {
            color: #1890ff;
          }
          li {
            float: left;
            padding-left: 20px;
            padding-right: 20px;
            text-align: center;
          }
        }
      }
    }

    .result-tab-top::-webkit-scrollbar {
      display: none;
    }

    .result-tab-bottom {
      height: 50px;
      width: 350px;
      float: right;

      button {
        height: 35px;
        width: 100px;
        margin-right: 10px;
        margin-top: 2%;
        border-radius: 6px;
        cursor: pointer;
      }

      button:first-child {
        margin-left: 20px;
        border: 1px solid #f5dab1;
        background-color: #fdf6ec;
        color: #e6a23c;
      }
      .button-first-child {
        transition:border-color 1.0s,color 1.0s;
        overflow:hidden;
        // transition-timing-function:ease;
        position:relative;
        z-index:1;
      }
      .button-first-child:hover::before{
        opacity:1;
        transform: translateX(0);
        transition-timing-function:ease
      }
      .button-first-child::before {
        content:"";
        position:absolute;
        z-index:-1;
        top:0;
        left:0;
        transition:transform 1.0s,background-color 1.0s;
        transform-origin:0% 100%;
        width: 0;
        height: 0;
        border-top: 100px solid #e6a23c;
        border-right: 150px solid transparent;
        transform: translateX(-100%);
      }
      button:first-child:hover {
        color: #fff;
      }

      button:nth-child(2) {
        border: 1px solid #fbc4c4;
        background-color: #fef0f0;
        color: #f56c6c;
      }
      .button-second-child, .button-third-child {
        transition:border-color 1.0s,color 1.0s;
        overflow:hidden;
        // transition-timing-function:ease;
        position:relative;
        z-index:1;
      }
      .button-second-child:hover::before, .button-third-child:hover::before{
        opacity:1;
        transform: translateX(0);
        transition-timing-function:ease
      }
      .button-second-child::before, .button-third-child::before {
        content:"";
        position:absolute;
        z-index:-1;
        top:0;
        left:0;
        transition:transform 1.0s,background-color 1.0s;
        transform-origin:0% 100%;
        width: 0;
        height: 0;
        border-top: 100px solid #f56c6c;
        border-right: 150px solid transparent;
        transform: translateX(-100%);
      }
      button:nth-child(2):hover {
        // background-color: #f56c6c;
        border: 1px solid #f56c6c;
        color: #fff;
      }
      .result-tab-bottom-popconfirm {
        display: inline-block;
        position: relative;
        button {
          border: 1px solid #fbc4c4;
          background-color: #fef0f0;
          color: #f56c6c;
          margin-left: 0;
        }
      }
      .result-tab-bottom-popconfirm button:hover {
        // background-color: #f56c6c;
        border: 1px solid #f56c6c;
        color: #fff;
      }
    }
  }

  .result-content {
    width: 95%;
    margin: 15px auto;
    background-color: #fff;
    border: 1px solid #dedede;

    .result-content-info {
      width: 98%;
      text-align: center;
      overflow: hidden;
      overflow-x: scroll;
      margin: 0 auto;
      padding-top: 20px;
      .result-content-info-box {
        font-size: 16px;
        color: #333;
        table {
          border: None;
          tr:last-child td {
            border-bottom: none;
            padding-bottom: 20px;
          }
          tr:hover {
            background: #f3f3f3;
          }
          th {
            padding: 10px 20px;
            border:none;
            background-color: #fafafa;
            border-bottom: 1px solid #e8e8e8;
          }
          td {
            padding: 10px 20px;
            border:none;
            border-bottom: 1px solid #e8e8e8;
          }
        }
      }
    }
    .result-content-info::-webkit-scrollbar {
      display: none;
    }
    .result-content-draw {
      width: 98%;
      height: 400px;
    }
  }
}
</style>
