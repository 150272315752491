<template>
  <div class="content">
    <FirstStep></FirstStep>
  </div>
</template>

<script>
import FirstStep from '@/components/Introduce/FirstStep.vue'
// import storage from 'store'
export default {
  name: 'HomeIndexName',
  components: {
    FirstStep
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 90%;
  height: 600px;
  background-color: #fff;
  margin: 100px auto;
  padding-bottom: 20px;
  font-size: 14px;
  color: #333;
  // dynamic
  // margin-top: 200px;
}
</style>
