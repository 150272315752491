<template>
  <div class="new-wapper-box">
    <div class="new" :style="{height: newBoxHeightWithUnit}">
      <div class="new-box">
        <form action="#">
          <ul>
            <li>
              <label>Project Name</label>
              <div>
                <input type="text" name="project_name" autocomplete="off" :placeholder="projectNamePlaceholder" v-model="projectName">
              </div>
            </li>
            <li>
              <label>Node Num</label>
              <div>
                <input type="text" name="node_num" autocomplete="off" :placeholder="nodeNumPlaceholder" v-model="nodeNum">
              </div>
            </li>
            <li>
              <label>Worker Num</label>
              <div>
                <input type="text" name="node_num" autocomplete="off" :placeholder="workerNumPlaceholder" v-model="workerNum">
              </div>
            </li>
            <li>
              <label>Rate</label>
              <div>
                <input type="text" name="rate" autocomplete="off" :placeholder="ratePlaceholder" v-model="rate">
              </div>
            </li>
            <li>
              <label><b>*</b>Host</label>
              <div>
                <input type="text" name="host" autocomplete="off" v-model="host" ref="hostInput" @blur="noBlur">
              </div>
            </li>
            <li :style="{height: pathsInputHeghtWithUnit}">
              <label>Paths</label>
              <div class="paths_input"  :style="{height: pathsInputHeghtWithUnit}">
                <div v-for="item in pathsInputList" :key="item.key">
                  <input type="text" name="paths" autocomplete="off" style="margin-bottom:10px;" :placeholder="pathsInputListPlaceholder" v-model="item.value" ref="pathsInput" @blur="noBlur"><i @click="deleteInput(item.key)"></i>
                </div>
                <button @click="addInput" class="button-add"><span class="button-add-span">+</span></button>
              </div>
            </li>
            <li>
              <label>Locust File</label>
              <p></p>
              <div @click="getFile" class="uploadfile">
                  <i></i>
                  <input type="file" name="uploadfile" class="upoloadfile" style="visibility: hidden;" ref="touchUploadEvent" @change="operateFile">
                  <span><b>Click to upload </b>locust file</span>
              </div>
              <p>{{ uploadFileName }}</p>
            </li>
          </ul>
          <input type="button" name="" value="Run" class="submit-btn" @click="submitData">
          <!-- <button class="submit-btn" @click="submitData">Run</button> -->
        </form>
      </div>
      <Loading :isShow="showLoading"></Loading>
    </div>
  </div>

</template>

<script>
import Loading from '@/components/Loading/Loading.vue'
export default {
  name: 'DataName',
  data () {
    return {
      projectNamePlaceholder: 'eg: *.ff.garena.all',
      nodeNumPlaceholder: 'the default is 4, the maximum is not more than 20',
      workerNumPlaceholder: 'peak concurrency',
      ratePlaceholder: 'users started/second',
      pathsInputListPlaceholder: 'eg: /api/info',

      nextKey: 1,
      pathsInputHeght: 80,
      newBoxHeight: 700,
      projectName: '',
      nodeNum: '',
      workerNum: '',
      rate: '',
      host: '',
      uploadFileName: '',
      uploadFileStr: '',
      pathsInputList: [
        { key: 0, value: '' }
      ],

      showLoading: false
    }
  },
  methods: {
    deleteInput (key) {
      if (this.pathsInputList.length === 1) {
        this.pathsInputList[0].value = ''
        return true
      }
      this.pathsInputList = this.pathsInputList.filter(item => item.key !== key)
      this.pathsInputHeght = this.pathsInputList.length * 40 + 40
    },
    addInput (e) {
      this.pathsInputList.push({
        key: this.nextKey,
        value: ''
      })
      this.nextKey++
      this.pathsInputHeght = this.pathsInputList.length * 40 + 40
      e.preventDefault()
    },
    getFile (e) {
      this.$refs.touchUploadEvent.click()
    },
    operateFile (e) {
      const fileNameList = e.target.files[0].name.split('.')
      if (fileNameList[fileNameList.length - 1] !== 'py') {
        this.$message({
          message: 'please upload python file',
          type: 'error',
          duration: '3000'
        })
        return true
      }
      this.uploadFileName = e.target.files[0].name
      this.$message({
        message: 'upload success',
        type: 'success',
        duration: '3000'
      })
      const reader = new FileReader()
      reader.readAsText(e.target.files[0], 'UTF-8')
      reader.onload = (evt) => {
        this.uploadFileStr = evt.target.result
      }
    },
    submitData (e) {
      // check data
      let isError = false
      if (this.host === '') {
        this.$refs.hostInput.style.border = '1px solid red'
        isError = true
      }
      if (isError) {
        this.$message({
          message: 'please check required',
          type: 'error',
          duration: '3000'
        })
        return true
      }
      for (let i = 0; i < this.pathsInputList.length; i++) {
        if ((this.pathsInputList[i].value === '' && this.uploadFileStr === '') || (this.pathsInputList[i].value !== '' && this.uploadFileStr !== '')) {
          this.$refs.pathsInput[i].style.border = '1px solid red'
          isError = true
        }
      }
      if (isError) {
        this.$message({
          message: 'paths and locust file can not exist both',
          type: 'error',
          duration: '3000'
        })
        return true
      }
      // add global data
      this.$bus.nodeNum = this.nodeNum === '' ? 4 : this.nodeNum
      this.$bus.workerNum = this.workerNum === '' ? 1 : this.workerNum
      this.$bus.rate = this.rate === '' ? 1 : this.rate
      this.$bus.host = this.host
      this.$bus.projectName = this.projectName === '' ? Math.random().toString(36).substr(2) : this.projectName

      const pathList = []
      this.pathsInputList.forEach(item => {
        pathList.push(item.value)
      })
      // loding
      this.showLoading = true
      // after check, loading & request
      // request create
      const createRequestParams = {
        works: this.$bus.workerNum,
        project_name: this.$bus.projectName,
        node_num: this.$bus.nodeNum
        // file_script: null,
        // paths: null
      }
      if (pathList[0] === '') {
        createRequestParams.file_script = this.uploadFileStr
      } else {
        createRequestParams.paths = pathList
      }
      this.$api.create(createRequestParams).then(response => {
        if (response.status === 'success') {
          this.$bus.locustName = response.data.name
          let locustDeployStatus = false
          const timer = setInterval(() => {
            // request check_status
            if (!locustDeployStatus) {
              this.$api.check_status({
                name: this.$bus.locustName
              }).then(response => {
                if (response.status === 'success') {
                  // eslint-disable-next-line no-const-assign
                  locustDeployStatus = response.data.task_status
                }
              })
            } else {
              clearInterval(timer)
              // request swarm
              this.$api.swarm({
                name: this.$bus.locustName,
                host: this.$bus.host,
                spawn_rate: this.$bus.rate,
                user_count: this.$bus.workerNum
              }).then(response => {
                if (response.status === 'success') {
                  clearInterval(timer)
                  this.$bus.createTime = response.data.create_time
                  this.showLoading = false
                  this.$bus.openResultWithBtn = true
                  this.$router.push('/home/result')
                }
              })
            }
          }, 5000)
        }
      })
    },
    noBlur (e) {
      e.target.style.border = '1px solid #b9b9b9'
    }
  },
  computed: {
    pathsInputHeghtWithUnit () {
      return this.pathsInputHeght + 'px'
    },
    newBoxHeightWithUnit () {
      return this.pathsInputHeght + this.newBoxHeight + 'px'
    }
  },
  components: {
    Loading
  },
  mounted () {
    if (this.$bus.hasNotClosedTask) {
      this.$message({
        message: 'You have tasks in progress!',
        type: 'error',
        duration: '3000'
      })
      this.$router.go(-1)
    }
    if (this.$bus.currentNotClosedTask) {
      this.$message({
        message: 'You have tasks in progress, please refresh!',
        type: 'error',
        duration: '3000'
      })
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.new-wapper-box {
  margin-top: 100px;
  height: 100%;
}
.new {
  width: 90%;
  height: 600px;
  margin: 0 auto;
  background-color: #fff;
  padding-top: 50px;

  .new-box {
    height: 100%;
    width:50%;
    // remove
    // background-color: #f7bfbf;
    margin: 0 auto;
  }

  .submit-btn {
    width: 30%;
    max-width: 260px;
    height: 40px;
    margin-left: 24%;
    margin-top: 60px;
    border: 1px solid #1890ff;
    border-radius: 10px;
    font-size: 18px;
    background-color: #1890ff;
    color: #fff;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 0.5s;
  }

  .submit-btn:hover {
    background-color: #5db0fe;
  }

  ul li:last-child {
    height: 100px;

    p {
      height:15px;
      width: 300px;
      margin-left: 25%;
      margin-top: 10px;
      line-height: 15px;
      font-size: 14px;
      color: #999;
      display: block;
    }
  }

  ul li {
    display: block;
    height: 40px;
    width: 100%;
    margin-top: 30px;

    .uploadfile {
      height: 100px;
      width: 34%;
      border: 1px dashed #333;
      border-radius: 10px;
      margin-left: 10px;
      cursor: pointer;
      transition-property: border;
      transition-duration: 0.5s;

      i {
        display: block;
        height: 60px;
        width: 60px;
        margin: 0px auto;
        background-image: url("../../../static/icon/upload.png");
        background-size: 60px 60px;
      }

      input {
        width: 1px;
        margin-left: 0;
      }

      span {
        display: inline-block;
        text-align: center;
        height: 20px;
        width: 98%;
        font-size: 12px;
        color: #999999;

        b {
          color: #1890ff;
        }
      }
    }
    .uploadfile:hover {
        border: 1px dashed #a6d4ff;
      }

    label {
      display: block;
      width: 22%;
      height: 40px;
      text-align: right;
      color: #333;
      font-size: 14px;
      float: left;
      line-height: 40px;

      b {
        color: red;
        margin-right: 5px;
      }
    }

    .paths_input {
      position: relative;
    }

    .button-add {
      height: 35px;
      width: 40%;
      font-size: 40px;
      line-height: 35px;
      background-color: #fff;
      color: #3d9ffb;
      border: 1px solid #3d9ffb;
      border-radius: 8px;
      cursor: pointer;
      position: absolute;
      left: 2%;
      bottom: 0;
      transition-property: background-color;
      transition-duration: 0.5s;
      transition: background-color 1.0s, color 1.0s;
      z-index: 1;
      overflow: hidden;
      .button-add-span {
        height: 30px;
        width: 30px;
        // background-color: #333;
        line-height: 30px;
        display: block;
        margin: 0 auto;
      }
    }
    .button-add:hover::before {
      transform: translateX(0);
      transition: transform 1.0s;
      transition-timing-function: ease;
    }
    .button-add:hover .button-add-span {
      transform: rotate(360deg);
      transition: transform 1.0s;
      transition-timing-function: ease;
    }
    .button-add::before {
      content: "";
      // width: 260px;
      // height: 40px;
      // background-color: #1890ff;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 0;
      height: 0;
      border-top: 200px solid #3d9ffb;
      border-right: 310px solid transparent;
      transform: translateX(-100%);
      transition: transform 1.0s;
    }

    button:hover {
      color: #fff;
    }

    div {
      // display: inline-block;
      height: 40px;
      width: 75%;
      float: left;

      input {
        font-size: 16px;
        color: #333;
        width: 86%;
        height: 35px;
        margin-left: 10px;
        text-indent: 10px;
        border-radius: 8px;
        border: 1px solid #b9b9b9;
        cursor: pointer;
        transition-property: border;
        transition-duration: 1s;
      }

      input::placeholder {
        color: #b9b9b9;
        font-size: 12px;
      }

      input:hover {
        border: 1px solid #a6d4ff;
      }

      input:focus {
        border: 1px solid #1890ff;
      }
    }
  }
}

.new .paths_input div {
  display: inline-block;
  width: 100%;
  input {
    width: 86%;
  }
}

.new .paths_input i {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-left: 12px;
  background-image: url("../../../static/icon/delete.png");
  background-size: 20px 20px;
  cursor: pointer;
}

.new .paths_input i:hover {
  background-image: url("../../../static/icon/delete_hover.png");
}
</style>
