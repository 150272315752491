import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/less/global.less'
import Message from '@/components/Message/index.js'
import api from './api'
import bus from './bus'
import VueCookies from 'vue-cookies'

Vue.config.productionTip = false

Vue.prototype.$message = Message
Vue.prototype.$api = api
Vue.prototype.$bus = bus

api.setting().then(response => {
  if (response.status === 'success') {
    // Vue.prototype.$bus.callback_url = response.data.callback_url
    // const apiConfig = {
    //   apiKey: response.data.callback_url,
    //   clientId: response.data.clientId || '609726394566-5fr15m63oucqq6e2kj7edlq377v1eqe2.apps.googleusercontent.com',
    //   scope: 'openid email profile',
    //   prompt: 'select_account'
    // }
    // Vue.use(VueGAPI, apiConfig)
    Vue.use(VueCookies)

    new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')
    Vue.prototype.$bus.callbackUrl = response.data.redirect_url
  }
})
