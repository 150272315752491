<template>
  <div class="box">
    <Nav></Nav>
    <div :style="{minHeight: height, marginTop: curMarginTop}">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Nav from '@/components/Nav/Nav.vue'
import Footer from '@/components/Footer/Footer.vue'

export default {
  name: 'HomeName',
  components: {
    Nav,
    Footer
  },
  data () {
    return {
      height: document.documentElement.clientHeight - 240 + 'px',
      curMarginTop: (this.$bus.hasNotClosedTask === true ? 220 : 0) + 'px',
      isShow: false
    }
  },
  watch: {
    height (val) {
      this.height = val - 300 + 'px'
    },
    '$bus.hasNotClosedTask': {
      handler (value) {
        this.curMarginTop = (value === true ? 220 : 0) + 'px'
      }
    },
    '$bus.userName': {
      handler (value) {
        if (value !== '') {
          this.isShow = true
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
  // .box-content {
  //   height: 1000px;
  //   width: 100%;
  //   remove
  //   background-color: antiquewhite;
  //   min-height: 1000px;
  // }
</style>
