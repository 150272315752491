import axios from 'axios'
import Vue from 'vue'
import router from '../router'
import VueCookies from 'vue-cookies'

const DELAY_TIME = 20000 // 请求超时时间
const regex = /.*csrftoken=([^;.]*).*$/ // 用于从cookie中匹配 csrftoken值

/* 创建axios实例 */
const service = axios.create({
  baseURL: '',
  timeout: DELAY_TIME,
  withCredentials: true
})

/* request拦截器 */
service.interceptors.request.use(
  // eslint-disable-next-line consistent-return
  config => {
    config.headers['X-CSRFToken'] = document.cookie.match(regex) ? document.cookie.match(regex)[1] : null
    return config
  }
)

/* respone拦截器 */
service.interceptors.response.use(
  response => {
    if (response.data.status === 'error') {
      if (response.data.msg === 'LOGIN_FAILD') {
        VueCookies.remove('is_login')
        VueCookies.remove('code')
        Vue.prototype.$message({
          message: 'login faild',
          type: 'error',
          duration: '3000'
        })
        router.push('/')
        return true
      }
      Vue.prototype.$message({
        message: response.data.msg,
        type: 'error',
        duration: '3000'
      })
    } else {
      return response.data
    }
  }
)

export default service
