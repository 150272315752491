<template>
  <transition name="fade">
    <div class="s-message" :class="{'success':type=='success','error':type=='error'}" v-show="show" :style="{top:top+'px'}">
      <img src="../../../static/icon/success.png" v-if="type=='success'">
      <img src="../../../static/icon/error.png" v-if="type=='error'">
      <span class="s-message-info">{{message}}</span>
    </div>
  </transition>
</template>

<script>
// import { setTimeout } from 'timers'
export default {
  name: 'MessageName',
  data () {
    return {
      type: 'error',
      top: 60
    }
  },
  created () {
    const arr = document.getElementsByClassName('s-message')
    this.top = 20 + this.top * (arr.length)
  }
}
</script>

<style lang='less' scoped>
.s-message{
  width:350px;
  position: fixed;
  left: 50%;
  line-height: 30px;
  margin-left:-175px;
  z-index: 10000;
  display: flex;
  padding: 10px;
  border-radius: 8px;
  box-sizing: border-box;
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  transition: opacity .3s,transform .3s,left .3s,right .3s,top .4s,bottom .3s;
  overflow: hidden;
  color: #606266;
  font-size: 13px;
  letter-spacing: 1px;
  img {
    height: 16px;
    margin: 8px 0 5px 5px;
  }

  .s-message-info{
    margin-left:10px;
  }
}

.fade-enter-active,.fade-leave-active {
  transition: opacity .3s
}
.fade-enter,.fade-leave-to{
  opacity: 0
}

.success{
  background-color: #f0f9eb;
  border: 1px solid #e1f3d8;
  color:#85CE61
}
.error{
  background-color: #fef0f0;
  color:#F56C6C;
  border: 1ps solid #fde2e2;
}
</style>
