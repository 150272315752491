<template>
  <div class="ranking-box" :style="{height: computedrankingContentHeight}">
    <div class="ranking-box-search">

    </div>
    <div class="ranking-box-detail">
      <div class="ranking-box-detail-right">
        <div class="ranking-box-detail-right-shadow" :style="{width: computedTableRightColumWith}">
          <table border="1" cellspacing="0">
            <thead>
              <tr>
                <th class="ranking-box-detail-right-position-th">Project</th>
                <th>Owner</th>
                <th>Create Time</th>
                <th>Cost Time</th>
                <th>Type</th>
                <th>Path</th>
                <th>#Request</th>
                <th>#Fail</th>
                <th>Median (m/s)</th>
                <th>Average (m/s)</th>
                <th>Min</th>
                <th>Max</th>
                <th>Average size</th>
                <th>RPS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in contentTable"  :key="item.id">
                <td class="ranking-box-detail-right-position"><router-link to="/home/detail"><b @click="showDetail(item.name)">{{ item.project_name }}</b></router-link></td>
                <td>{{ item.user_name }}</td>
                <td>{{ formateTime(item.created_at) }}</td>
                <td>{{ comCostTime(item.created_at, item.updated_at) }}</td>
                <td>{{ item.type }}</td>
                <td>{{ item.path }}</td>
                <td>{{ item.request }}</td>
                <td>{{ item.fail }}</td>
                <td>{{ item.median }}</td>
                <td>{{ item.average }}</td>
                <td>{{ item.min }}</td>
                <td>{{ item.max }}</td>
                <td>{{ item.average_size }}</td>
                <td>{{ item.current_rps }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTime } from '@/utils/assist'
export default {
  name: 'RankName',
  data () {
    return {
      tableLeftColumWith: 0,
      tablerightColumWith: 0,
      tableRowNumber: 0,
      contentTable: []
    }
  },
  methods: {
    comCostTime (v1, v2) {
      const resTime = v2 - v1
      let resHour = 0
      let resMin = 0
      if (resTime < 60) {
        return '00:00:' + `${resTime}`
      }
      if (resTime > 3600) {
        resHour = Math.floor(resTime / 3600)
        resMin = Math.floor((resTime - (resHour * 3600)) / 60)
      } else {
        resMin = Math.floor((resTime - (resHour * 3600)) / 60)
      }
      resHour = resHour.toString().length === 2 ? resHour.toString() : '0' + resHour.toString()
      resMin = resMin.toString().length === 2 ? resMin.toString() : '0' + resMin.toString()
      return `${resHour}:${resMin}:00`
    },
    showDetail (value) {
      this.$bus.locustName = value
      this.$router.push('/detail')
    },
    formateTime (time) {
      return getTime(time)
    },
    initRank () {
      // detail
      this.$api.ranking().then(response => {
        this.contentTable = response.data.rank
      })
    }
  },
  mounted () {
    this.initRank()
  },
  computed: {
    computedTableRightColumWith () {
      this.contentTable.forEach(item => {
        if (item.path.length > this.tablerightColumWith) {
          this.tablerightColumWith = item.path.length
        }
        this.tableRowNumber++
      })
      return (this.tablerightColumWith * 8) + 1680 + 'px'
    },
    computedrankingContentHeight () {
      return (this.tableRowNumber * 50) + 200 + 'px'
    }
  }
}
</script>

<style lang="less" scoped>
.ranking-box {
  width: 90%;
  height: 1500px;
  background-color: #fff;
  margin: 100px auto;
  // dynamic
  // margin-top: 200px;

  .ranking-box-detail {
    width: 95%;
    margin: 10px auto;
    position: relative;

    .ranking-box-detail-right {
      padding-top: 20px;
      padding-bottom: 20px;
      overflow: hidden;
      overflow-x: scroll;
      text-align: center;
      font-size: 16px;
      color: #333;
      .ranking-box-detail-right-shadow {

        table {
          border: None;
          .ranking-box-detail-right-position, .ranking-box-detail-right-position-th {
            position:sticky;
            left:0;
            background:#fff;
          }
          .ranking-box-detail-right-position-th {
            background:#fafafa;
          }
          th {
            padding: 10px 20px;
            border:none;
            background-color: #fafafa;
            border-bottom: 1px solid #e8e8e8;
          }
          td {
            padding-left: 20px;
            padding-right: 10px;
            height: 50px;
            border:none;
            border-bottom: 1px solid #e8e8e8;
            b {
              color: #1890ff;
              cursor: pointer;
            }
          }
          tr:hover .ranking-box-detail-right-position {
            background-color: #e8e8e8;
          }
          tr:hover {
            background-color: #e8e8e8;
          }
        }
      }
    }
  }
}
</style>
