<template>
  <div class="login-box">
    <div class="login-box-wapper">
      <div class="login-box-inner">
        <div class="login-title">
          <p>Black Cloud</p>
        </div>
        <div class="login-content">
          <div class="login-content-inner" @click="GarenaSSO">
            <div class="login-logo"></div>
            <span>Garena SSO Login</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="login-loding">
      <div class="login-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div> -->
    <div class="login-footer">
      <p>Copyright © 2022 Made By Garena SH Web Team</p>
      <p>Version: 1.0</p>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import storage from 'store'
// import Cookie from '../../utils/cookie'
export default {
  name: 'LoginName',
  methods: {
    GoogleAuth () {
      const loginParams = {
        id_token: ''
      }
      if (Vue.prototype.$gapi.isAuthenticated()) {
        const currentUser = storage.get('gapi.session')
        loginParams.id_token = currentUser.idToken
        this.ServerLogin(loginParams)
      } else {
        // google oauth
        this.$gapi.login().then(() => {
          const currentUser = storage.get('gapi.session')
          loginParams.id_token = currentUser.idToken
          this.ServerLogin(loginParams)
        })
      }
    },
    ServerLogin () {
      // eslint-disable-next-line prefer-const
      let resParams = this.$route.query
      // eslint-disable-next-line no-prototype-builtins
      if ((resParams.hasOwnProperty('code') && resParams.hasOwnProperty('state')) || this.$cookies.get('is_login') === 1) {
        this.$api.login({ code: resParams.code }).then((res) => {
          this.$bus.userName = res.data.name
          const background = document.getElementById('watermark')
          const imgData = `https://watermark.sec.garenanow.com/wm-api/watermark?mode=mix&token=${res.data.code}`
          background.style.backgroundImage = `url(${imgData})`
          this.$cookies.set('is_login', 1, 12 * 60 * 60)
          this.$cookies.set('code', resParams.code, 12 * 60 * 60)
          this.$router.push('/home/index')
        })
      }
    },
    GarenaSSO () {
      this.$api.setting().then((response) => {
        this.$bus.resCode = ''
        this.$cookies.set('is_login', 0, 12 * 60 * 60)
        window.location = this.$bus.callbackUrl
      })
    }
  },
  created () {
    // this.GoogleAuth()
    // this.GarenaSSO()
    this.ServerLogin()
  }
}
</script>

<style lang="less" scoped>
.login-box {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: fixed;
  z-index:999;

  // .login-loding {
  //   height: 10%;
  //   width: 5%;
  //   // background-color: rgb(49, 51, 111);
  //   position: absolute;
  //   top: 70%;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   min-height: 80px;
  //   min-width: 80px;
  //   .login-roller {
  //     display: inline-block;
  //     position: relative;
  //   }
  //   .login-roller div {
  //     animation: login-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  //     transform-origin: 40px 40px;
  //   }
  //   .login-roller div:after {
  //     content: " ";
  //     display: block;
  //     position: absolute;
  //     width: 7px;
  //     height: 7px;
  //     border-radius: 50%;
  //     background: #1890ff;
  //     margin: -4px 0 0 -4px;
  //   }
  //   .login-roller div:nth-child(1) {
  //     animation-delay: -0.036s;
  //   }
  //   .login-roller div:nth-child(1):after {
  //     top: 63px;
  //     left: 63px;
  //   }
  //   .login-roller div:nth-child(2) {
  //     animation-delay: -0.072s;
  //   }
  //   .login-roller div:nth-child(2):after {
  //     top: 68px;
  //     left: 56px;
  //   }
  //   .login-roller div:nth-child(3) {
  //     animation-delay: -0.108s;
  //   }
  //   .login-roller div:nth-child(3):after {
  //     top: 71px;
  //     left: 48px;
  //   }
  //   .login-roller div:nth-child(4) {
  //     animation-delay: -0.144s;
  //   }
  //   .login-roller div:nth-child(4):after {
  //     top: 72px;
  //     left: 40px;
  //   }
  //   .login-roller div:nth-child(5) {
  //     animation-delay: -0.18s;
  //   }
  //   .login-roller div:nth-child(5):after {
  //     top: 71px;
  //     left: 32px;
  //   }
  //   .login-roller div:nth-child(6) {
  //     animation-delay: -0.216s;
  //   }
  //   .login-roller div:nth-child(6):after {
  //     top: 68px;
  //     left: 24px;
  //   }
  //   .login-roller div:nth-child(7) {
  //     animation-delay: -0.252s;
  //   }
  //   .login-roller div:nth-child(7):after {
  //     top: 63px;
  //     left: 17px;
  //   }
  //   .login-roller div:nth-child(8) {
  //     animation-delay: -0.288s;
  //   }
  //   .login-roller div:nth-child(8):after {
  //     top: 56px;
  //     left: 12px;
  //   }
  //   @keyframes login-roller {
  //     0% {
  //       transform: rotate(0deg);
  //     }
  //     100% {
  //       transform: rotate(360deg);
  //     }
  //   }
  // }
  .login-footer {
    position: absolute;
    height: 4%;
    width: 16%;
    bottom: 6%;
    left: 50%;
    transform: translateX(-50%);
    min-width: 283px;
    min-height: 17px;
    p {
      font-size: 12px;
      color: #333;
      text-align: center;
    }
  }
}
.login-box-wapper{
  box-shadow: 0 0 10px #c6c8cc;
  border-radius: 10px;
  min-width: 400px;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);
  .login-box-inner{
    padding: 10%;
  }
  .login-title {
    height: 8%;
    width: 100%;
    p {
      font-size: 30px;
      font-weight: 900;
      text-align: center;
    }
  }
  .login-content{
    padding: 20% 10% 10% 10%;
    &-inner{
      cursor: pointer;
      height:60px;
      width:100%;
      line-height: 60px;
        .login-logo{
          background-color: #f1f6fc;
          height:60px;
          width:60px;
          border-radius: 60px;
          border: 1px solid #f1f6fc;
          float: left;
          margin-right:30px;
          background: url('../../../static/icon/login-logo.png') no-repeat 50%;
          background-size: 70% 70%;
        }
        .login-desc{
          display: block;
          height:60px;
          width: 70%;
          float: left;
        }
    }

  }
}
.login-content-inner:hover{
  .login-logo{
    border: 1px solid rgb(99,197,255);
  }
}
</style>
