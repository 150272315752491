import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home/Home.vue'
import Rank from '../views/Rank/Rank.vue'
import History from '../views/History/History.vue'
import Result from '../views/New/Result.vue'
import Data from '../views/New/Data.vue'
import Detail from '../views/Detail/Detail.vue'
import Login from '../views/Login/Login.vue'
import Index from '../views/Home/Index.vue'
import cookies from 'vue-cookies'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    redirect: '/home/index',
    component: Home,
    children: [
      {
        path: 'index',
        component: Index
      },
      {
        path: 'data',
        component: Data
      },
      {
        path: 'result',
        component: Result
      },
      {
        path: 'rank',
        component: Rank
      },
      {
        path: 'history',
        component: History
      },
      {
        path: 'detail',
        component: Detail
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  const asd = cookies.get('is_login')
  if (asd !== null) {
    next()
  } else {
    if (to.path === '/login') {
      next({ query: { nextUrl: to.fullPath } })
    } else {
      next({
        path: '/login',
        query: { nextUrl: to.fullPath }
      })
    }
  }
})

export default router
