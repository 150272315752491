<template>
  <div class="loading-mask" v-show="isShow">
    <div class="loading-box" :style="{height: heightProp, width: widthProp}">
      <div class="loading-box-content">
        <div class="loading-box-content-header">
          <ul>
            <li>
              <div>已提交至服务端
                <i class="loading-box-content-header-first-circular"></i>
              </div>
            </li>
            <li>
            <div>k8s分配资源
              <i class="loading-box-content-header-first-circular"></i>
            </div>
            </li>
            <li>
              <div>压测
                <i class="loading-box-content-header-second-circular"></i>
                <i class="loading-box-content-header-third-circular">
                </i>
              </div>
            </li>
          </ul>
        </div>
        <div class="loading-box-content-body">
          <img src="../../../static/icon/success.png" alt="">
        </div>
        <div class="loading-box-content-footer">
          <p>压测完成, <b>{{ times }}</b>秒跳转到详情页...</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompletedName',
  props: {
    heightProp: {
      type: String,
      default: '50%'
    },
    widthProp: {
      type: String,
      default: '50%'
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      times: 5
    }
  },
  methods: {
  },
  watch: {
    isShow () {
      setInterval(() => {
        this.times--
        if (this.times === 0) {
          clearInterval(this.timer)
          this.$router.push('/home/detail')
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.loading-mask{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(55,55,55,.6);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.loading-box{
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;

  .loading-box-content {
    height: 86%;
    width: 90%;
    margin: 4% auto;

    .loading-box-content-header{
      height: 20%;
      width: 80%;
      margin: 10px auto;

      ul li {
        div{
          position: relative;
          height: 100%;
          width: 33%;
          float: left;
          padding-top: 4%;
          text-align: center;
          font-size: 12px;
          color: #999;
          border-top: 1px solid #999;

          i {
            position: absolute;
            height: 17px;
            width: 17px;
            background-image: url("../../../static/icon/success.png");
            background-size: 17px 17px;
            top:-13px;
          }

          .loading-box-content-header-first-circular {
            left:-10px;
          }
          .loading-box-content-header-second-circular {
            left:-10px;
          }
          .loading-box-content-header-third-circular {
            right:-14px;
          }
        }
      }

      ul li div{
        height: 100%;
        width: 33%;
        background-color: #fff;
        float: left;
      }
    }

    .loading-box-content-body {
      position: relative;
      height: 50%;
      width: 40%;
      margin: 10px auto;
      img {
        height: 80%;
        clear: both;
        display: block;
        margin:auto;
      }
    }

    .loading-box-content-footer {
      margin-top: 20px;
      height: 10%;
      width: 100%;
      font-size: 14px;
      color: #999;
      text-align: center;
    }
  }
}
</style>
