<template>
  <router-link to="/home/index">
    <div class="nav-box-left" @click="onClickTab(-1)">
      <img src='../../../static/icon/logo.png' class="nav-box-logo">
      <h1>BLACKCLOUD</h1>
    </div>
  </router-link>
</template>

<script>
export default {
  methods: {
    onClickTab (value) {
      this.$emit('onClickHome', value)
    }
  }
}
</script>

<style lang="less" scoped>
.nav-box-left {
  width: 200px;
  height: 70px;
  line-height: 70px;
  font-size: 10px;
  font-weight: 200px;
  color: #1890ff;
  float: left;
  cursor: pointer;

  h1 {
    display: block;
    margin-left: 10px;
    float: left;
  }

  .nav-box-logo {
    display: block;
    height: 40px;
    width: 40px;
    margin-top: 15px;
    float: left;
  }
}
</style>
