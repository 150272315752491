<template>
  <div class='app'>
    <div id='watermark'></div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  created () {
    if (Number(this.$cookies.get('is_login')) === 1 && this.$cookies.get('code') !== '') {
      this.$api.login({ code: this.$cookies.get('code') }).then((res) => {
        this.$bus.userName = res.data.name
        const background = document.getElementById('watermark')
        const imgData = `https://watermark.sec.garenanow.com/wm-api/watermark?mode=mix&token=${res.data.code}`
        background.style.backgroundImage = `url(${imgData})`
        this.$router.push('/home/index')
      })
    }
  }
}
</script>

<style lang='less' scoped>
#watermark {
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  pointer-events: none;
  top: 0;
  z-index: 1000;
}
</style>
