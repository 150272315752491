<template>
  <div class="nclose-task-box"  v-show="isShowTask">
    <p>You have tasks in progress, please give priority to them!</p>
    <div class="nclose-task-info">
      <ul :style="{width: ulWidth}">
        <li>
          <span>Project Name</span>
          <p>{{ this.$bus.projectName }}</p>
        </li>
        <li>
          <span>Create Time</span>
          <p>{{ this.$bus.createTime }}</p>
        </li>
        <li>
          <span>Cost Time</span>
          <p>{{ this.$bus.costTime }}</p>
        </li>
        <li>
          <span>Host</span>
          <p>{{ this.$bus.host }}</p>
        </li>
        <li>
          <span>Status</span>
          <p>{{ this.$bus.status }}</p>
        </li>
        <li>
          <span>Nodes</span>
          <p>{{ this.$bus.nodeNum }}</p>
        </li>
        <li>
          <span>RPS</span>
          <p>{{ this.$bus.rate }}</p>
        </li>
      </ul>
    </div>
    <div class="nclose-task-button">
      <button @click="onEnterDetail">Enter Detail</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotClosedTaskName',
  props: {
    isShow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShowTask: this.isShow
    }
  },
  methods: {
    onEnterDetail () {
      this.isShowTask = false
      this.$bus.hasNotClosedTask = false
      this.$bus.openResultWithBtn = true
      this.$router.push('/home/result')
    }
  },
  watch: {
    isShow (value) {
      this.isShowTask = value
    }
  },
  computed: {
    ulWidth () {
      return ((this.$bus.host.length + this.$bus.projectName.length) * 8) + 550 + 'px'
    }
  }
}
</script>

<style lang="less" scoped>
.nclose-task-box {
  width: 90%;
  height: 120px;
  margin: 10px auto;
  border: 1px solid #fbc4c4;
  background-color: #fef0f0;
  color: #f56c6c;
  border-radius: 6px;
  p {
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .nclose-task-info {
    float: left;
    text-align: center;
    height: 70%;
    width: 65%;
    min-width: 100px;
    overflow: hidden;
    overflow-x: scroll;
    margin-left: 10%;
  }
  // .nclose-task-info::-webkit-scrollbar {
  //   // background-color: #fbc4c4;
  //   border: 1px solid #fbc4c4;
  //   height: 5px;
  // }
  // .nclose-task-info::-webkit-scrollbar-thumb {
  //   background: #fbc4c4;
  // }
  .nclose-task-info ul {
    display: block;
    width: 1400px;
    li {
      color: #7e7e7e;
      float: left;
      span {
        display: block;
        padding-top: 10px;
        padding-left: 30px;
        text-align: center;
      }
      p {
        display: block;
        padding-top: 5px;
        padding-left: 30px;
        text-align: center;
      }
    }
  }
  .nclose-task-button {
    width: 20%;
    height: 75%;
    float: right;

    button {
      height: 30px;
      width: 100px;
      border-radius: 5px;
      margin-top: 20px;
      border: 1px solid #f5dab1;
      background-color: #fdf6ec;
      color: #e6a23c;
      cursor: pointer;
    }
  }
  button:first-child:hover {
    background-color: #e6a23c;
    border: 1px solid #e6a23c;
    color: #fff;
  }
}
</style>
