<template>
  <div class="loading-mask" v-show="isShow">
    <div class="loading-box" :style="{height: heightProp, width: widthProp}">
      <div class="loading-box-content">
        <div class="loading-box-content-header">
          <ul>
            <li>
              <div>已提交至服务端
                <i class="loading-box-content-header-first-circular"></i>
              </div>
            </li>
            <li>
              <div>等待k8s分配资源
                <i class="loading-box-content-header-second-circular"></i>
                <i class="loading-box-content-header-third-circular">
                  <svg class="circular" viewBox="25 25 50 50">
                    <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
                  </svg>
                </i>
              </div>
            </li>
          </ul>
        </div>
        <div class="loading-box-content-body">
          <svg class="circular" viewBox="25 25 50 50">
            <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10"/>
          </svg>
        </div>
        <div class="loading-box-content-footer">
          <p>正在为您分配k8s资源, 请耐心等待</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingpName',
  props: {
    heightProp: {
      type: String,
      default: '50%'
    },
    widthProp: {
      type: String,
      default: '50%'
    },
    isShow: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" scoped>
.loading-mask{
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(55,55,55,.6);
  z-index: 100;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-box{
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 6px;

  .loading-box-content {
    height: 86%;
    width: 90%;
    margin: 4% auto;

    .loading-box-content-header{
      height: 20%;
      width: 80%;
      margin: 10px auto;

      ul li {
        div{
          position: relative;
          height: 100%;
          width: 50%;
          float: left;
          padding-top: 4%;
          text-align: center;
          font-size: 12px;
          color: #999;
          border-top: 1px solid #999;

          i {
            position: absolute;
            height: 17px;
            width: 17px;
            background-image: url("../../../static/icon/success.png");
            background-size: 17px 17px;
            top:-13px;
          }

          .loading-box-content-header-first-circular {
            left:-10px;
          }
          .loading-box-content-header-second-circular {
            left:-10px;
          }
          .loading-box-content-header-third-circular {
            right:-20px;
            border:3px solid #85CE61;
            border-radius: 50%;
            background-image: none;
          }
        }
      }

      ul li div{
        height: 100%;
        width: 50%;
        background-color: #fff;
        float: left;
      }
    }

    .loading-box-content-body {
      position: relative;
      height: 50%;
      width: 40%;
      margin: 10px auto;
    }

    .loading-box-content-footer {
      margin-top: 20px;
      height: 10%;
      width: 100%;
      font-size: 14px;
      color: #999;
      text-align: center;
    }
  }
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes color {
  100%,
  0% {
    stroke: #85CE61;
  }
  40% {
    stroke: #85CE61;
  }
  66% {
    stroke: #85CE61;
  }
  80%,
  90% {
    stroke: #85CE61;
  }
}
</style>
