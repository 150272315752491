<template>
  <div class="detail">
    <div class="detail-tab">
      <div class="detail-tab-top">
        <ul class="dynamicdetailHeaderWidthCompute">
          <li>
            <span>Owner</span>
            <p>{{ owner }}</p>
          </li>
          <li>
            <span>Create Time</span>
            <p>{{ formateTime(createTime) }}</p>
          </li>
          <li>
            <span>Cost Time</span>
            <p>{{ comCostTime }}</p>
          </li>
          <li>
            <span>Host</span>
            <p>{{ host }}</p>
          </li>
          <li>
            <span>Status</span>
            <p>{{ status }}</p>
          </li>
          <li>
            <span>Nodes</span>
            <p>{{ nodes }}</p>
          </li>
          <li>
            <span>RPS</span>
            <p>{{ rps }}</p>
          </li>
          <li>
            <span>failures</span>
            <p>{{ failures }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="detail-content">
      <div class="detail-content-info">
        <div class="detail-content-info-box">
          <table border="1" cellspacing="0">
            <thead>
              <tr>
                <th width='10%'>Type</th>
                <th width='10%'>Path</th>
                <th width='12%'>#Request</th>
                <th width='10%'>#Fail</th>
                <th width='10%'>Median (m/s)</th>
                <th width='10%'>Average (m/s)</th>
                <th width='8%'>Min</th>
                <th width='8%'>Max</th>
                <th width='10%'>Average size</th>
                <th width='10%'>RPS</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in contentTable"  :key="index">
                <td>{{ item.type }}</td>
                <td>{{ item.path }}</td>
                <td>{{ item.request }}</td>
                <td>{{ item.fail }}</td>
                <td>{{ item.median }}</td>
                <td>{{ item.average }}</td>
                <td>{{ item.min }}</td>
                <td>{{ item.max }}</td>
                <td>{{ item.average_size }}</td>
                <td>{{ item.current_rps }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTime } from '@/utils/assist'
export default {
  data () {
    return {
      // header
      owner: this.$bus.userName,
      createTime: 0,
      endTime: 0,
      host: 'https://shooting.web.test.freefiremobile.com',
      status: 'End',
      nodes: 4,
      rps: 400,
      failures: 0,
      // content
      contentTable: []
    }
  },
  name: 'DetailName',
  methods: {
    loadContentTable (responseData) {
      this.contentTable = responseData.data.history.filter(item => item.type !== '')
      const currentItem = responseData.data.history.filter(item => item.type === '')
      this.failures = Math.floor((currentItem[0].fail / currentItem[0].request) * 100) + '%'
    },
    formateTime (time) {
      return getTime(time)
    },
    initDetail () {
      // task info
      this.$api.task_info({
        name: this.$bus.locustName
      }).then(response => {
        response.data.history.forEach(item => {
          this.createTime = item.created_at
          this.endTime = item.updated_at
          this.host = item.host
          this.nodes = item.node_num
          this.rps = item.rate
        })
      })
      // detail
      this.$api.detail({
        name: this.$bus.locustName
      }).then(response => {
        this.loadContentTable(response)
      })
    }
  },
  computed: {
    comCostTime () {
      const resTime = this.endTime - this.createTime
      let resHour = 0
      let resMin = 0
      if (resTime < 60) {
        return '00:00:' + `${resTime}`
      }
      if (resTime > 3600) {
        resHour = Math.floor(resTime / 3600)
        resMin = Math.floor((resTime - (resHour * 3600)) / 60)
      } else {
        resMin = Math.floor((resTime - (resHour * 3600)) / 60)
      }
      resHour = resHour.toString().length === 2 ? resHour.toString() : '0' + resHour.toString()
      resMin = resMin.toString().length === 2 ? resMin.toString() : '0' + resMin.toString()
      return `${resHour}:${resMin}:00`
    }
  },
  mounted () {
    this.initDetail()
    document.querySelector('.dynamicdetailHeaderWidthCompute').style.width = (this.host.length * 8) + 750 + 'px'
    let maxlength = 0
    this.contentTable.forEach(item => {
      if (item.path.length > maxlength) {
        maxlength = item.path.length
      }
    })
  }
}
</script>

<style lang="less" scoped>
.detail {
  width:90%;
  margin: 0 auto;
  //dynamic
  // margin-top: 200px;

  .detail-tab {
    height: 100px;
    width: 95%;
    border: 1px solid #dedede;
    margin: 0 auto;
    margin-top: 100px;
    color: #333;
    font-size: 15px;
    background-color: #fff;

    .detail-tab-top {
      height:100px;
      width: 96%;
      margin: 0 auto;
      overflow: hidden;
      overflow-x: scroll;
      padding: 14px;

      ul {
        li:hover {
          color: #1890ff;
        }
        li {
          float: left;
          padding: 12px 15px 12px;
          text-align: center;
        }
      }
    }

    .detail-tab-top::-webkit-scrollbar {
      display: none;
    }

    .detail-tab-bottom {
      height: 30%;
      width: 100%;

      button:first-child {
        margin-left: 20px;
        border: 1px solid #f5dab1;
        background-color: #fdf6ec;
        color: #e6a23c;
      }
      button:first-child:hover {
        background-color: #e6a23c;
        background-color: #e6a23c;
        color: #fff;
      }

      button:nth-child(2) {
        border: 1px solid #fbc4c4;
        background-color: #fef0f0;
        color: #f56c6c;
      }
      button:nth-child(2):hover {
        background-color: #f56c6c;
        border: 1px solid #f56c6c;
        color: #fff;
      }
    }
  }

  .detail-content {
    width: 95%;
    margin: 15px auto;
    background-color: #fff;
    border: 1px solid #dedede;

    .detail-content-info {
      width: 98%;
      text-align: center;
      overflow: hidden;
      overflow-x: scroll;
      margin: 0 auto;
      padding-top: 20px;
      .detail-content-info-box {
        font-size: 16px;
        color: #333;
        table {
          margin: 0 auto;
          border: None;
          margin-bottom: 20px;
          tr:last-child td {
            border-bottom: none;
            padding-bottom: 20px;
          }
          tr:hover {
            background: #f3f3f3;
          }
          th {
            padding: 10px 20px;
            border:none;
            background-color: #fafafa;
            border-bottom: 1px solid #e8e8e8;
          }
          td {
            padding: 10px 20px;
            border:none;
            border-bottom: 1px solid #e8e8e8;
          }
        }
      }
    }
  }
}
</style>
