<template>
  <div class="popconfirm-box" v-show="isShowPop">
    <div class="popconfirm-box-pointer">
      <div class="popconfirm-box-pointer-top">
      </div>
    </div>
    <div class="popconfirm-box-content">
      <div class="popconfirm-box-content-left">
        ?
      </div>
      <div class="popconfirm-box-content-right">
        结束压测,并释放k8s资源?
      </div>
    </div>
    <div class="popconfirm-box-button">
      <button @click="onCancel">取消</button>
      <button @click="onOk">确定</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopConfirmName',
  props: {
    isShow: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      isShowPop: false
    }
  },
  watch: {
    isShow (value) {
      if (value % 2 === 0) {
        this.isShowPop = true
      } else {
        this.isShowPop = false
      }
    }
  },
  methods: {
    onCancel () {
      this.closePop(false)
    },
    onOk () {
      this.closePop(true)
    },
    closePop (value) {
      this.isShowPop = false
      this.$emit('popConfirmChange', value)
    }
  }
}
</script>

<style lang="less" scoped>
.popconfirm-box {
  width: 200px;
  height: 100px;
  background-color: #fff;
  position: absolute;
  bottom: -120px;
  left: -50px;
  border: 1px solid #ebeef5;
  border-radius: 6px;

  .popconfirm-box-pointer {
    height: 20px;
    width: 20px;
    margin: 0 auto;
    position: relative;

    .popconfirm-box-pointer-top {
      border: 10px solid;
      border-color: transparent transparent #ebeef5 transparent;
      position: absolute;
      top:-20px;
      left: 0;
    }
    .popconfirm-box-pointer-top::before{
      content: '';
      width: 0;
      height: 0;
      border: 9px solid;
      position: absolute;
      top: -8px;
      left: -9px;
      border-color: transparent transparent #fff  transparent;
    }
  }

  .popconfirm-box-content {
    height: 40%;
    width: 100%;
    float: left;
    .popconfirm-box-content-left {
      width: 20px;
      height: 20px;
      background-color: #ff9900;
      border-radius: 50%;
      text-align: center;
      color: #fff;
      font-size: 12px;
      line-height: 20px;
      margin-left: 10px;
      margin-top: 10px;
      float: left;
    }
    .popconfirm-box-content-right {
      height: 40px;
      width: 160px;
      margin-left: 10px;
      float: left;
      font-size: 13px;
      line-height: 40px;
      color: #606266;
    }
  }
  .popconfirm-box-button {
    height: 40%;
    width: 100%;
    float: left;

    button {
      height: 25px;
      width: 60px;
      margin-left: 25px;
      margin-top: 5px;
      cursor: pointer;
    }
    button:first-child {
      border: none;
      background-color: #fff;
      color: #409eff;
    }
    button:last-child {
      border: 1px solid #409eff;
      background-color: #409eff;
      color: #fff;
    }
    button:last-child:hover {
      background-color: #68b4ff;
    }
  }
}

</style>
