import Vue from 'vue'
import main from './Message.vue'

const Constructor = Vue.extend(main)
let newMessage

const Message = function (val) {
  const obj = { show: false }
  Object.assign(obj, val)

  newMessage = new Constructor({
    data: obj
  })

  let vm = newMessage.$mount()
  const el = vm.$el
  document.body.appendChild(el)
  vm.show = true

  setTimeout(() => {
    vm.show = false
    document.body.removeChild(el)
    newMessage.$destroy()
    vm = null
  }, obj.duration)
}

export default Message
