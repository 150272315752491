<template>
  <div class="home_first_step">
    <div v-html="markdownToHtml"></div>
    <!-- <div class="step-new" ref="stepNewRef" @click="showStepSwitch('new')">
      <span class="step-new-title">New</span>
      <span class="step-new-pointer" ref="stepNewPointerRef">></span>
      <div class="step-new-content" ref="stepNewContentRef">
        <ul>
            <li>第一步: 点击右上方的“NEW”,创建一个新的压测</li>
            <li>第二步: 填写压测相关信息(点击“RUN”之后,会进入资源分配过程,需要等一会)</li>
            <li>第三步: 资源分配完成后会进入压测数据显示页面</li>
        </ul>
      </div>
    </div>
    <div class="step-history" ref="stepHistoryRef" @click="showStepSwitch('history')">
      <span class="step-history-title">History</span>
      <span class="step-history-pointer" ref="stepHistoryPointerRef">></span>
      <div class="step-history-content" ref="stepHistoryContentRef">
        <ul>
            <li>用于查看历史压测信息</li>
        </ul>
      </div>
    </div>
    <div class="step-ranking" ref="stepRankingRef" @click="showStepSwitch('ranking')">
      <span class="step-ranking-title">Ranking</span>
      <span class="step-ranking-pointer" ref="stepRankingPointerRef">></span>
      <div class="step-ranking-content" ref="stepRankingContentRef">
        <ul>
            <li>压测数据排行榜,仅用于展示</li>
        </ul>
      </div>
    </div> -->
  </div>
</template>

<script>
import { marked } from 'marked'
import fileMd from '../../../static/mk/homeIntroduce.md?row'

export default {
  name: 'FirstStepName',
  data () {
    return {
      // markdown: '# Hello World'
      markdown: marked('## SQL标准中的隔离级别')
    }
  },
  computed: {
    markdownToHtml () {
      return marked(fileMd)
    }
  },
  methods: {
    showNewStep (height, top, rotate) {
      this.$refs.stepNewRef.style.height = height + 'px'
      this.$refs.stepNewContentRef.style.top = top + 'px'
      this.$refs.stepNewPointerRef.style.transform = 'rotate(' + rotate + 'deg)'
    },
    showHistoryStep (height, top, rotate) {
      this.$refs.stepHistoryRef.style.height = height + 'px'
      this.$refs.stepHistoryContentRef.style.top = top + 'px'
      this.$refs.stepHistoryPointerRef.style.transform = 'rotate(' + rotate + 'deg)'
    },
    showRankingStep (height, top, rotate) {
      this.$refs.stepRankingRef.style.height = height + 'px'
      this.$refs.stepRankingContentRef.style.top = top + 'px'
      this.$refs.stepRankingPointerRef.style.transform = 'rotate(' + rotate + 'deg)'
    },
    showStepSwitch (stepValue) {
      switch (stepValue) {
        case 'new':
          this.showNewStep(450, 50, 90)
          this.showHistoryStep(40, -450, 0)
          this.showRankingStep(40, -450, 0)
          break
        case 'history':
          this.showNewStep(40, -450, 0)
          this.showHistoryStep(450, 50, 90)
          this.showRankingStep(40, -450, 0)
          break
        case 'ranking':
          this.showNewStep(40, -450, 0)
          this.showHistoryStep(40, -450, 0)
          this.showRankingStep(450, 50, 90)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.home_first_step {
  width: 100%;
  padding-top: 20px;
  div {
    height: 40px;
    width: 80%;
    margin: 0 auto;
    line-height: 30px;
    position: relative;
    cursor: pointer;
    p {
      margin-left: 20px;
    }
  }
  .step-new {
    border-top: 1px solid #ddd;
  }
  .step-new, .step-history, .step-ranking {
    width:80%;
    overflow: hidden;
    border-bottom: 1px solid #ddd;
    .step-new-content, .step-history-content, .step-ranking-content {
      width: 90%;
      height: 400px;
      position: absolute;
      left:50%;
      transform: translate(-50%);
      top:-450px;
      ul li {
        font-size: 12px;
        color: #333;
      }
    }
    span {
      display: block;
      height: 40px;
      line-height: 40px;
    }
    .step-new-title, .step-history-title, .step-ranking-title{
      width:100px;
      margin-left: 50px;
      float: left;
    }
    .step-new-pointer, .step-history-pointer, .step-ranking-pointer{
      width:40px;
      text-align: center;
      margin-right: 50px;
      float: right;
    }
  }
}
.step-new:hover span, .step-history:hover span, .step-ranking:hover span {
  color: #1890ff;
}
</style>
